import bg from '../../../assets/home/header/background.lg.jpg';
import bgsc from '../../../assets/home/header/background-secondary.lg.jpg';
import woman from '../../../assets/home/header/woman.lg.png';
import Slider from "react-slick";
import { motion } from "framer-motion";
import Button from '../../../entity/button';
import { Link } from 'react-scroll';
import { useContext } from 'react';
import { ModalContext } from '../../../App';

export default function Element() {
    const { setIsOpen } = useContext(ModalContext);

    return <div className="w-full h-screen lg:h-[calc(100vh-84px)] relative px-[10px] md:px-5 lg:px-10">
        <Slider fade={true} autoplay={true} autoplaySpeed={10000} >
            <div className="w-full h-screen lg:h-[calc(100vh-84px)]">
                <div className="relative z-50 w-full h-full flex bg-cover bg-no-repeat bg-center rounded-[30px]" style={{ backgroundImage: `linear-gradient(270deg,rgba(198,148,59,0) 29.54%,#422302 112.17%),linear-gradient(180deg,rgba(248,219,166,0) 19.77%,#422302 118.89%), url(${bg})` }}>
                    <div className="container w-full h-full flex items-end justify-between px-[54px]">
                        <div className="flex flex-col justify-end w-full h-full pb-[54px] md:text-center lg:text-left items-center lg:items-start relative z-50">
                            <motion.div
                                initial={{ x: -100, opacity: 0 }}
                                viewport={{ once: true }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 0.7 }}
                                className="hidden md:flex justify-between items-center relative z-50"
                            >
                                <button className="group p-[2px] bg-[rgba(217,217,217,0.29)] rounded-[19.24px] border-[0.5px] border-white relative">
                                    <span className={`py-[10px] px-[18px] inline-flex justify-center items-center text-black text-[11.12px] leading-[120%] font-medium 
              relative overflow-hidden group-hover:text-white transition-all duration-100`
                                    }>
                                        Наши Туры
                                        <span className='group-hover:-right-full transition-all linear duration-100 absolute block top-0 right-0 w-full h-full bg-white rounded-full -z-20' />
                                    </span>
                                    <span className="py-[10px] px-[18px] inline-flex bg-transparent justify-center items-center text-white text-[11.12px] leading-[120%] font-medium relative overflow-hidden group-hover:text-[#111]">
                                        Выберите свою путевку
                                        <span className='group-hover:left-0 transition-all linear duration-100 absolute block top-0 -left-full w-full h-full bg-white rounded-full -z-20' />
                                    </span>
                                </button>
                            </motion.div>
                            <motion.h1
                                initial={{ x: -100, opacity: 0 }}
                                viewport={{ once: true }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 0.7 }}
                                className={`
                                text-white font-extrabold leading-[110%] text-[41px] text-center
                                md:leading-[101%] md:text-[48px] md:mt-8 md:text-left`
                                }
                            >
                                Asiatique Travel
                            </motion.h1>
                            <p className='font-normal md:text-center md:text-[36px] lg:text-[48px] text-[18.26px] leading-[124%] md:leading-[101%] text-white lg:text-left text-center max-w-[825px]'>
                                Дух степей<br className='md:block hidden' />
                                в каждом путешествии
                            </p>
                            <motion.div initial={{ x: -100, opacity: 0 }} viewport={{ once: true }} animate={{ x: 0, opacity: 1 }} transition={{ duration: 0.7 }} className="flex gap-3 md:gap-[42px] mt-4 md:mt-8 justify-center md:justify-start">
                                <Button variant="primary" onClick={() => setIsOpen(true)}>
                                    <span>Оставить заявку</span>
                                </Button>
                                <Link to='directions'>
                                    <Button variant="secondary">
                                        <span>Смотреть Направления</span>
                                    </Button>
                                </Link>
                            </motion.div>
                        </div>
                        <div className='w-full h-full flex justify-center md:justify-end items-end lg:w-1/2 absolute lg:static bottom-0 left-0 right-0 z-10'>
                            <motion.div
                                initial={{ x: 100, opacity: 0 }}
                                viewport={{ once: true }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 0.7 }}
                                className={
                                    `bg-contain bg-no-repeat bg-bottom lg:bg-[right_bottom] left-7 right-7 w-[412px] h-full`
                                }
                                style={{ backgroundImage: `url(${woman})` }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full h-screen">
                <div className="relative z-20 w-full h-full flex bg-cover bg-no-repeat bg-center rounded-[30px]" style={{ backgroundImage: `linear-gradient(270deg,rgba(198,148,59,0) 29.54%,#422302 112.17%),linear-gradient(180deg,rgba(248,219,166,0) 19.77%,#422302 118.89%), url(${bgsc})` }}>
                    <div className="container w-full h-full flex items-end justify-between px-[54px]">
                        <div className="flex flex-col lg:w-1/2 w-full justify-end h-full pb-[54px] items-center lg:items-start relative z-50">
                            <motion.div
                                initial={{ x: -100, opacity: 0 }}
                                viewport={{ once: true }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 0.7 }}
                                className="hidden md:flex justify-between items-center"
                            >
                                <button className="group p-[2px] bg-[rgba(217,217,217,0.29)] rounded-[19.24px] border-[0.5px] border-white relative">
                                    <span className={`py-[10px] px-[18px] inline-flex justify-center items-center text-black text-[11.12px] leading-[120%] font-medium 
              relative overflow-hidden group-hover:text-white transition-all duration-100`
                                    }>
                                        Наши Туры
                                        <span className='group-hover:-right-full transition-all linear duration-100 absolute block top-0 right-0 w-full h-full bg-white rounded-full -z-20' />
                                    </span>
                                    <span className="py-[10px] px-[18px] inline-flex bg-transparent justify-center items-center text-white text-[11.12px] leading-[120%] font-medium relative overflow-hidden group-hover:text-[#111]">
                                        Выберите свою путевку
                                        <span className='group-hover:left-0 transition-all linear duration-100 absolute block top-0 -left-full w-full h-full bg-white rounded-full -z-20' />
                                    </span>
                                </button>
                            </motion.div>
                            <motion.h1
                                initial={{ x: -100, opacity: 0 }}
                                viewport={{ once: true }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 0.7 }}
                                className={`
                                text-white font-extrabold leading-[110%] text-[41px] text-center
                                md:leading-[101%] md:text-[48px] md:mt-8 md:text-left`
                                }
                            >
                                Asiatique Travel
                            </motion.h1>
                            <p className='lg:w-[825px] font-normal font-manrope lg:text-[22px] md:text-[16px] text-[18.26px] leading-[124%] md:leading-[101%] text-white lg:text-left text-center max-w-[825px]'>
                                Наша философия основана на духе кочевых народов, странствующих по бескрайним просторам Средней Азии. Мы приглашаем вас путешествовать с открытым сердцем, готовыми к новым горизонтам и впечатлениям.
                            </p>
                            <motion.div initial={{ x: -100, opacity: 0 }} viewport={{ once: true }} animate={{ x: 0, opacity: 1 }} transition={{ duration: 0.7 }} className="flex gap-3 md:gap-[42px] mt-4 md:mt-8 justify-center md:justify-start">
                                <Button variant="primary">
                                    <span>Оставить заявку</span>
                                </Button>
                                <Button variant="secondary" href='/#directions'>
                                    <span>Смотреть Направления</span>
                                </Button>
                            </motion.div>
                        </div>
                        <div className='w-full h-full flex justify-center md:justify-end items-end lg:w-1/2 absolute lg:static bottom-0 left-0 right-0 z-10'>
                            <motion.div
                                initial={{ x: 100, opacity: 0 }}
                                viewport={{ once: true }}
                                animate={{ x: 0, opacity: 1 }}
                                transition={{ duration: 0.7 }}
                                className={
                                    `bg-contain bg-no-repeat bg-bottom lg:bg-[right_bottom] left-7 right-7 w-[412px] h-full`
                                }
                                style={{ backgroundImage: `url(${woman})` }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Slider>
    </div>
}
