export const fetchTeam = async () => {
    // const response = await fetch("/fetch-team.php");
    // return response.json();

    const response = [
        {
            id: 0,
            job: "Должность",
            name: "Имя",
            image: "/team/1.jpg",
        },
        {
            id: 0,
            job: "Должность",
            name: "Имя",
            image: "/team/1.jpg",
        },
        {
            id: 0,
            job: "Должность",
            name: "Имя",
            image: "/team/1.jpg",
        },
        {
            id: 0,
            job: "Должность",
            name: "Имя",
            image: "/team/1.jpg",
        },
        {
            id: 0,
            job: "Должность",
            name: "Имя",
            image: "/team/1.jpg",
        },
        {
            id: 0,
            job: "Должность",
            name: "Имя",
            image: "/team/1.jpg",
        },
        {
            id: 0,
            job: "Должность",
            name: "Имя",
            image: "/team/1.jpg",
        },
        {
            id: 0,
            job: "Должность",
            name: "Имя",
            image: "/team/1.jpg",
        },
        {
            id: 0,
            job: "Должность",
            name: "Имя",
            image: "/team/1.jpg",
        }
    ]

    return response;
}