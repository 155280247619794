import team from "../../../assets/home/team/background.png"
import { fetchTeam } from "../../../features/team/index"
import Slider from "./slider"
import { useEffect, useState } from "react";

export default function Team() {
    const [data, setData] = useState<any[]>([]);

    useEffect(() => {
        fetchTeam().then((data) => {
            setData(data);
        })
    }, [])
    
    return (
    <div className="w-full bg-cover bg-no-repeat bg-center pt-8 pb-14" style={{
        backgroundImage: `url(${team})`
    }}>
        <div className="container">
            <div className="pt-10 pb-6 rounded-[30px] border border-white backdrop-blur-[17.1px] px-8 md:px-[76px] lg:px-0 text-center">
                <h2 className="text-black text-[28px] md:text-[32px] lg:text-[48px] font-semibold text-center w-full">
                    Команда
                </h2>
                <p className="text-[#111] text-[14px] md:text-[16px] lg:text-[24px] font-normal text-center leading-[120%] max-w-[720px] mx-auto">
                    Каждое путешествие, организованное нами-профессионалами в туризме, наполнено жаждой открытия и уважением к разным культурам. Cледуйте за зовом степного ветра вместе с нами
                </p>
                <Slider data={data} />
            </div>
        </div>
    </div>
    )
}
