export const fetchReviews = async () => {
    // const response = await fetch("/get-reviews.php");
    // return response.json();

    return [
        {
            id: 1,
            name: "Иван Иванов",
            avatar: "/reviews/1.png",
            status: "Постоянный клиент",
            background: "/reviews/1_bg.jpg",
            review: "\"Спасибо Asiatique Travel за незабываемое путешествие! 🌍✨ Всё было организовано идеально – от перелёта до экскурсий. Уже планирую следующую поездку с вами! 😊💖\"",
        },
        {
            id: 1,
            name: "Тест",
            avatar: "/reviews/1.png",
            status: "Постоянный клиент",
            background: "/reviews/1_bg.jpg",
            review: "\"Спасибо Asiatique Travel за незабываемое путешествие! 🌍✨ Всё было организовано идеально – от перелёта до экскурсий. Уже планирую следующую поездку с вами! 😊💖\"",
        },
        {
            id: 1,
            name: "Тест",
            avatar: "/reviews/1.png",
            status: "Постоянный клиент",
            background: "/reviews/1_bg.jpg",
            review: "\"Спасибо Asiatique Travel за незабываемое путешествие! 🌍✨ Всё было организовано идеально – от перелёта до экскурсий. Уже планирую следующую поездку с вами! 😊💖\"",
        }
    ]
}
