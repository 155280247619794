import Header from "../widgets/home/header";
import Booking from "../widgets/home/booking";
import Directions from "../widgets/home/directions";
import Team from "../widgets/home/team";
import Reviews from "../widgets/home/reviews";
import Elemen from "../widgets/home/header/elemt";
import { Element } from "react-scroll";

export default function Home() {
  return <div>  
    {/* <Element name="home">
    <Header />
    </Element> */}
    <Element name="home">
    <Elemen />
    </Element>
    {/* <Element name="booking">
    <Booking />
    </Element> */}
    <Element name="directions">
    <Directions />
    </Element>
    <Element name="team">
    <Team />
    </Element>
    <Reviews />
  </div>;
}
