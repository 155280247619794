import { useEffect, useContext, useState } from 'react';
import { MenuContext } from '../../App';
import logo from '../../assets/navbar/logo.svg';
import { Link } from 'react-router-dom';
import plan from '../../assets/navbar/plane.svg';

export default function Navbar() {
  const isMobile = window.innerWidth < 1200;
  const { isOpen, setIsOpen } = useContext(MenuContext);

  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (isMobile) {
        if (window.scrollY > 16) {
          setIsFixed(true);
        } else {
          setIsFixed(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return <div className={`w-full transition-all duration-500 px-[22px] md:px-10 fixed z-50 top-0 left-0 right-0 lg:static py-4 md:mt-0 md:py-0 ${isFixed ? 'bg-[#151515]' : ''}`}>
    <div className="md:container mx-auto md:py-5">
      <div className="flex justify-between items-center">
        <Link to="/">
        <div className="flex items-center gap-2">
          <svg className='mb-[7px] md:mb-0' width="54" height="44" viewBox="0 0 54 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.1506 19.7078C23.7731 30.7183 30.4358 39.8534 34.2432 41.6608C38.5814 43.7202 43.4295 43.6641 47.8429 41.5035C53.5949 38.687 55.6419 32.3426 52.5902 26.7892C51.5201 24.842 49.5623 22.9881 47.647 22.108C45.5205 21.1313 42.3911 21.002 39.9698 21.791C35.3214 23.3053 32.4198 26.7988 33.0446 30.1282C33.4122 32.0885 35.848 33.4679 37.8711 32.8616C39.0121 32.5197 39.5101 31.8003 39.1157 31.0635C38.6524 30.1976 38.8033 29.289 39.4849 28.8424C40.0752 28.4558 40.1891 28.4599 41.0917 28.9008C43.7931 30.2205 43.3886 33.6152 40.3032 35.5229C38.6154 36.5664 36.7098 36.6998 34.6075 35.9216C31.8036 34.884 25.2236 28.6808 17.9503 18.4223L16.1506 19.7078Z" fill={`${isMobile? "white": "#151515"}`}/>
            <path d="M23.9528 10.3772C24.9679 9.52308 25.6427 9.22408 27.5373 8.78933C30.6415 8.07717 34.5723 8.58596 37.5458 10.0846C40.1523 11.3981 43.4362 14.5337 45.0079 17.2093L45.5634 18.1551L43.1501 18.1683C37.3175 18.1999 32.2951 21.275 29.9743 26.236L29.3984 27.4672L27.6054 25.2076C26.6195 23.9648 25.3913 22.3566 24.8766 21.6337L23.9408 20.3196L25.4821 20.1798C28.8801 19.8718 31.6629 16.9812 31.0217 14.4262C30.615 12.8065 28.2772 11.6398 26.8267 12.3326C25.7124 12.8651 25.7764 13.6572 26.9714 14.1267C28.342 14.665 28.5333 15.4615 27.5196 16.4084C26.6578 17.2136 25.1357 17.5697 24.1529 17.1962C22.9993 16.7576 22.3953 15.7155 22.3953 14.1637C22.3953 12.4221 22.8574 11.2989 23.9528 10.3772Z" fill={`${isMobile? "white": "#151515"}`}/>
            <path d="M9.74846 26.4175C8.16165 26.4203 8.89104 25.6261 11.9785 23.9882C12.9254 23.4858 13.7941 23.1328 13.9088 23.2038C14.0919 23.3169 14.5446 23.945 16.4111 26.6759C18.9831 30.439 23.9958 36.6716 27.0167 39.8632L29.0922 42.0557L27.472 42.5948C21.9657 44.4272 15.2277 44.3012 11.1178 42.2892C8.15445 40.8381 6.40876 38.5062 5.87785 35.2886C5.24462 31.4535 7.8084 28.8252 11.0021 30.0354C12.5328 30.6154 13.6928 32.2706 13.4517 33.5316C13.2789 34.4348 12.393 34.7798 11.0931 34.45C10.2213 34.2286 9.95593 34.2577 9.5037 34.6238C8.23801 35.6488 9.9693 37.2151 12.3685 37.2151C15.8391 37.2151 17.4904 34.1227 15.8738 30.6511C14.8405 28.4318 11.9202 26.4136 9.74846 26.4175Z" fill={`${isMobile? "white": "#151515"}`}/>
            <path d="M6.88268 6.09119C6.82334 5.65365 6.76441 5.23721 7.02374 5.05327C7.24953 4.89312 7.75751 4.99105 8.15066 5.19183C8.66869 5.45619 9.39105 6.15181 10.1397 7.10729C10.2875 7.29605 10.8211 7.9941 11.317 8.64539C11.7254 9.18194 12.1081 9.68685 12.2283 9.84921L12.3686 10.0384L15.5275 9.62612C17.265 9.39939 18.8662 9.20726 19.086 9.19901C20.2618 9.15532 20.6539 9.26812 21.0601 9.76729L21.1837 9.91904L20.9447 10.0346C20.8133 10.0981 19.3506 10.778 17.6945 11.5453C17.1865 11.7807 16.6774 12.0217 16.2151 12.2431C15.1931 12.7324 14.8883 13.9243 15.5251 14.8615C16.1735 15.8157 16.8364 16.8338 17.0519 17.3651C17.0519 17.3651 17.4349 17.3448 17.8512 17.3097C18.9395 17.2182 19.944 17.1615 20.2041 17.1771C20.4829 17.1939 20.6196 17.2419 20.7257 17.3605L20.808 17.4526L19.5077 18.0724C18.7926 18.4133 18.0863 18.7516 17.9384 18.8238L17.6695 18.9553L17.7752 19.216C17.8335 19.3594 17.8791 19.4883 17.8766 19.5026C17.8713 19.5323 17.5937 19.7296 17.5271 19.7544C17.4814 19.8092 17.2032 20.0061 17.1736 20.0012C17.1593 19.9989 17.0525 19.9133 16.9365 19.8109L16.7256 19.6248L16.5126 19.8352C16.3953 19.9509 15.8428 20.5056 15.2845 21.0679L14.2698 22.09L14.2101 21.982C14.1331 21.8427 14.1329 21.6979 14.2093 21.4291C14.2466 21.2975 14.3709 20.9785 14.5451 20.5607C14.7024 20.1833 14.9006 19.7254 15.1124 19.2523C15.2831 18.871 15.4287 18.5164 15.4287 18.5164C14.9969 18.1359 14.2332 17.1648 13.5302 16.2318C12.8483 15.3267 11.6346 15.2367 10.8436 16.0481C10.4928 16.4079 10.1079 16.8034 9.72381 17.1988C8.4521 18.5082 7.32707 19.6637 7.22347 19.7669L7.03544 19.9542L6.93308 19.7874C6.59645 19.2389 6.61943 18.8317 7.04956 17.7363C7.12985 17.5317 7.84068 16.0839 8.6289 14.5192L10.0623 11.6742L9.93014 11.4793C9.67903 11.1092 8.26853 8.96988 8.03289 8.6016C7.37863 7.5792 6.96092 6.66748 6.88268 6.09119Z" fill={`${isMobile? "white": "#151515"}`}/>
          </svg>
          <h1 className="text-[21px] font-manrope text-white lg:text-[#111]">
            ASIATIQUE TRAVEL
          </h1>
        </div>
        </Link>
        
        <div className="hidden lg:flex items-center">
          <ul className="flex items-center lg:gap-12">
            <li>Главная</li>
            <li>О нас</li>
            <li>Найти Тур</li>
            <li>Отзывы</li>
            <li>Контакты</li>
          </ul>
        </div>
        <div className="w-[57px] h-[57px] p-1 block lg:hidden rounded-full shadow-lg bg-white" onClick={() => {
          setIsOpen(!isOpen);
        }}>
          <img src={plan} alt="menu" className='w-full h-full object-contain' />
        </div>
      </div>
    </div>
  </div>;
}

