import bg from '../../assets/modal/bg.jpg';
import InputMask from 'react-input-mask';
import { useFormik } from 'formik';

export default function ModalContent() {
    const formik = useFormik({
        initialValues: {
            name: '',
            phone: '',
            city: '',
        },
        onSubmit: (values) => {
            console.log(values);
        },
    })

    return (
        <div className="w-[341px] md:w-[706px] font-manrope lg:w-[1056px] h-[576px] md:h-[356px] lg:h-[652px] rounded-[30px] overflow-hidden">
            <div className="w-full h-full py-6 px-4 flex flex-col md:flex-row gap-2" style={{background: 'linear-gradient(180deg, rgba(255, 215, 0, 0.1) -8.33%, rgba(255, 193, 7, 0.25) 26.69%, rgba(255, 165, 0, 0.4) 71.36%, #B8860B 106.67%), linear-gradient(180deg, #DCC26B 2.61%, rgba(234, 220, 154, 0.85) 48.25%, #BC973C 100%), linear-gradient(180deg, #DCC26B 4.56%, #EADC9A 48.25%, #BC973C 100%)'}}>
                <div className="w-full h-[197px] md:h-full uppercase text-[27px] md:text-[52px] lg:text-[66px] rounded-[16px] leading-[81.8px] font-bold text-white text-center bg-cover bg-no-repeat bg-center" style={{backgroundImage: `url(${bg})`, backgroundPosition: 'center', backgroundSize: '105% 105%'}}>
                    Asiatique
                </div>
                <form className="w-full h-full flex flex-col gap-6 bg-white rounded-[16px] px-6 pt-8 md:px-9 md:pt-3 lg:p-11 box-border" onSubmit={formik.handleSubmit}>
                    <div className="w-full flex flex-col gap-2 md:text-[10px] lg:text-[16px]">
                        <span>
                            Ваше имя
                        </span>
                        <input type="text" required className="w-full h-[30px] lg:h-[47px] rounded-[10px] border bg-[#EEE] p-2 box-border" {...formik.getFieldProps('name')} />
                    </div>
                    <div className="w-full flex flex-col gap-2 md:text-[10px] lg:text-[16px]">
                        <span>
                            Номер мобильного телефона
                        </span>
                        <input type="tel" required className="w-full h-[30px] lg:h-[47px] rounded-[10px] border bg-[#EEE] p-2 box-border" {...formik.getFieldProps('phone')} placeholder="+7 (___) ___ __ __" />
                    </div>
                    <div className="w-full flex flex-col gap-2 md:text-[10px] lg:text-[16px]">
                        <span>
                            Город
                        </span>
                        <input type="text" required className="w-full h-[30px] lg:h-[47px] rounded-[10px] border bg-[#EEE] p-2 box-border" {...formik.getFieldProps('city')} />
                    </div>
                    <button type="submit" className="w-full h-[30px] lg:h-[47px] flex items-center justify-center rounded-[10px] border bg-[#FCD229] p-2 box-border">
                        Отправить
                    </button>
                </form>
            </div>
        </div>
    )
}
