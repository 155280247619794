import './App.css';
import Home from './pages/index';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './widgets/navbar';
import Footer from './widgets/footer';
import Countries from './pages/countries';
import { createContext, useState } from 'react';
import CountriesJson from './utils/countries.json';
import Modal from './entity/modal';
import ModalContent from './widgets/modal';
import Country from './pages/countries/[id]';
import { Country as CountryType } from './utils/home';
import Menu from './widgets/menu';
import { Element } from 'react-scroll';
export const ModalContext = createContext<{
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}>({
  isOpen: false,
  setIsOpen: () => {},
});

export const MenuContext = createContext<{
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}>({
  isOpen: false,
  setIsOpen: () => {},
});

export const CountriesContext = createContext<CountryType[]>(CountriesJson.countries);

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <ModalContext.Provider value={{ isOpen, setIsOpen }}>
      <MenuContext.Provider value={{ isOpen: isMenuOpen, setIsOpen: setIsMenuOpen }}>
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
          <ModalContent />
        </Modal>
      <CountriesContext.Provider value={CountriesJson.countries as CountryType[]}>
      <Router>
        <Menu onClose={() => setIsMenuOpen(false)} />
          <Navbar />
          <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
            <ModalContent />
          </Modal>
          <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/countries" element={<Countries />} />
          <Route path="/countries/:id" element={<Country />} />
        </Routes>
        <Element name="contacts">
        <Footer />
        </Element>
      </Router>
      </CountriesContext.Provider>
      </MenuContext.Provider>
    </ModalContext.Provider>
  );
}

export default App;
