import { Link as ScrollLink } from "react-scroll";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { MenuContext } from "../../App";
import { useContext, useRef, useEffect } from "react";

interface MenuProps {
    onClose: () => void;
}

export default function Menu({ onClose }: MenuProps) {
    const ref = useRef<HTMLDivElement>(null);
    const { isOpen } = useContext(MenuContext);
    const location = useLocation();
    const Link = location.pathname === '/' ? ScrollLink : RouterLink;
    

    useEffect(() => {
        const handleOutsideClick = (event: any) => {
          // @ts-ignore
          if (ref.current && !ref.current.contains(event.target as Node)) {
            onClose();
          }
        };
        
        if (isOpen) {
          document.addEventListener('mousedown', handleOutsideClick);
        }
        
        return () => {
          document.removeEventListener('mousedown', handleOutsideClick);
        };
      }, [isOpen, onClose]);
    return <div ref={ref} className={`${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-all duration-300 fixed top-0 right-0 w-[256px] h-full z-[99] bg-black/50 backdrop-blur-sm`}>
        <ul className="flex flex-col gap-4 text-white text-[18px] font-manrope text-center mt-[130px]">
            <li className="cursor-pointer hover:text-yellow-500 hover:bg-black/50 p-2 rounded-md"><Link to={location.pathname === '/' ? 'main' : '/'} spy smooth offset={-100}>Главная</Link></li>
            <li className="cursor-pointer hover:text-yellow-500 hover:bg-black/50 p-2 rounded-md"><Link to={location.pathname === '/' ? 'booking' : '/booking'} spy smooth offset={-100}>Процесс бронирования</Link></li>
            <li className="cursor-pointer hover:text-yellow-500 hover:bg-black/50 p-2 rounded-md"><Link to={location.pathname === '/' ? 'services' : '/services'} spy smooth offset={-100}>Наши услуги</Link></li>
            <li className="cursor-pointer hover:text-yellow-500 hover:bg-black/50 p-2 rounded-md"><Link to={location.pathname === '/' ? 'team' : '/team'} spy smooth offset={-100}>Команда</Link></li>
            <li className="cursor-pointer hover:text-yellow-500 hover:bg-black/50 p-2 rounded-md"><Link to={location.pathname === '/' ? 'contacts' : '/contacts'} spy smooth offset={-100}>Контакты</Link></li>
        </ul>
    </div>;
}
