import { useContext } from "react"
import { CountriesContext } from "../../../App"
import { useParams } from "react-router-dom";

export default function Enterance() {
    const countries = useContext(CountriesContext);
    const { id } = useParams();

    const country = countries.find((_, index) => index === parseInt(id as string));
    
    return (
        <div className="w-full mt-10">
            <div className="container px-[10px] flex flex-col gap-4 md:flex-row md:gap-5 text-center md:text-left">
                <div className="w-full pt-[18px] pb-10 lg:pt-7 lg:py-8 md:py-10 md:px-7 rounded-[30px] bg-[#ECFFFF]">
                    <h2 className="text-[26px] lg:text-[30px] font-semibold text-[#151515]">
                        Условия Въезда
                    </h2>
                    <p className="text-[16px] lg:text-[18px] font-normal text-[#151515]">
                        {country?.entryConditions}
                    </p>
                </div>
                <div className="w-full pt-[18px] pb-10 lg:pt-7 lg:py-8 md:py-10 md:px-7 rounded-[30px] bg-[#ECFFFF]">
                    <h2 className="text-[26px] lg:text-[30px] font-semibold text-[#151515]">
                        Расположение
                    </h2>
                    <p className="text-[16px] lg:text-[18px] font-normal text-[#151515]">
                        {country?.location}
                    </p>
                </div>
            </div>
        </div>
    )
}
