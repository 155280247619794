import { useState } from "react";

interface ButtonSliderProps {
    onClick: () => void;
    direction: 'left' | 'right';
    className?: string;
}

export default function ButtonSlider({onClick, direction, className}: ButtonSliderProps) {
    const [focused, setFocused] = useState(false)
    return (
        <button onFocus={() => setFocused(true)} onBlur={() => setFocused(false)} className={`w-[50px] focus:w-[95px] transition-all duration-300 h-[50px] rounded-[50px] bg-white flex items-center ${direction === 'left' ? 'focus:justify-start focus:pl-2' : 'focus:justify-end focus:pr-2'} justify-center border border-[#B7B7B7] focus:border-[#FFA500] focus:bg-[#FFA500] ${className}`} onClick={onClick}>
            {direction === 'left' ? (
                <svg width="26" height="21" viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.39982 9.44344H24.1706C24.4528 9.44344 24.7233 9.55398 24.9228 9.75073C25.1223 9.94747 25.2344 10.2143 25.2344 10.4926C25.2344 10.7708 25.1223 11.0377 24.9228 11.2344C24.7233 11.4312 24.4528 11.5417 24.1706 11.5417H4.39982L12.1588 19.1919C12.3585 19.3889 12.4708 19.6561 12.4708 19.9347C12.4708 20.2133 12.3585 20.4805 12.1588 20.6775C11.9591 20.8745 11.6881 20.9851 11.4057 20.9851C11.1232 20.9851 10.8523 20.8745 10.6525 20.6775L1.0788 11.2353C0.979741 11.1379 0.901146 11.0221 0.84752 10.8947C0.793893 10.7672 0.766289 10.6306 0.766289 10.4926C0.766289 10.3546 0.793893 10.2179 0.84752 10.0905C0.901146 9.96301 0.979741 9.84724 1.0788 9.74979L10.6525 0.307669C10.8523 0.110672 11.1232 0 11.4057 0C11.6881 0 11.9591 0.110672 12.1588 0.307669C12.3585 0.504667 12.4708 0.771853 12.4708 1.05045C12.4708 1.32905 12.3585 1.59623 12.1588 1.79323L4.39982 9.44344Z" fill={`${focused? "white": "#151515"}`}/>
                </svg>
            ): (
                <svg width="26" height="21" transform="rotate(180)" viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.39982 9.44344H24.1706C24.4528 9.44344 24.7233 9.55398 24.9228 9.75073C25.1223 9.94747 25.2344 10.2143 25.2344 10.4926C25.2344 10.7708 25.1223 11.0377 24.9228 11.2344C24.7233 11.4312 24.4528 11.5417 24.1706 11.5417H4.39982L12.1588 19.1919C12.3585 19.3889 12.4708 19.6561 12.4708 19.9347C12.4708 20.2133 12.3585 20.4805 12.1588 20.6775C11.9591 20.8745 11.6881 20.9851 11.4057 20.9851C11.1232 20.9851 10.8523 20.8745 10.6525 20.6775L1.0788 11.2353C0.979741 11.1379 0.901146 11.0221 0.84752 10.8947C0.793893 10.7672 0.766289 10.6306 0.766289 10.4926C0.766289 10.3546 0.793893 10.2179 0.84752 10.0905C0.901146 9.96301 0.979741 9.84724 1.0788 9.74979L10.6525 0.307669C10.8523 0.110672 11.1232 0 11.4057 0C11.6881 0 11.9591 0.110672 12.1588 0.307669C12.3585 0.504667 12.4708 0.771853 12.4708 1.05045C12.4708 1.32905 12.3585 1.59623 12.1588 1.79323L4.39982 9.44344Z" fill={`${focused? "white": "#151515"}`}/>
                </svg>
            )}
        </button>
    )
}
