interface ButtonProps {
    children: React.ReactNode
    type?: 'primary' | 'secondary'
    onClick?: () => void
}

export function Button({children, type = 'primary', onClick}: ButtonProps) {
    return <button 
        className={`py-[11px] hover:bg-[#FFA500] transition-all duration-300 px-4 rounded-[19.5px] text-white text-[15px] leading-[120%] font-medium ${type === 'primary' ? 'bg-[#2C2C2C]' : 'bg-white border border-[#E1E1E1] text-[#1A1A1A]'}`}
        onClick={onClick}
    >
        {children}
    </button>
}



