import { CountriesContext } from "../../../App";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import capital from "../../../assets/countries/casd.svg";
import timezone from "../../../assets/countries/capital.svg";
import lang from "../../../assets/countries/timezone.svg";
import currency from "../../../assets/countries/lang.svg";

export default function Info() {
    const countries = useContext(CountriesContext);
    const { id } = useParams();

    const country = countries.find((_, index) => index === parseInt(id as string));

    return (
        <div className="w-full mt-10 mb-7">
            <div className="container px-[10px] text-center">
                <div className="w-full flex flex-col gap-4 md:grid md:grid-cols-2 md:gap-4">
                    <div className="w-full rounded-[30px] bg-white border border-[#D9D9D9] shadow-md py-4 md:py-8">
                        <img src={currency} alt={country?.name} width={67} height={67} className="block mx-auto" />
                        <h2 className="text-[30px] lg:text-[30px] font-semibold text-[#151515]">
                            Валюта
                        </h2>
                        <p className="text-[18px] font-normal text-[#151515]">
                            {country?.currency.name} {country?.currency.code}
                        </p>
                    </div>
                    <div className="w-full rounded-[30px] bg-white border border-[#D9D9D9] shadow-md py-4">
                        <img src={lang} alt={country?.name} width={67} height={67} className="block mx-auto" />
                        <h2 className="text-[30px] lg:text-[30px] font-semibold text-[#151515]">
                            Язык    
                        </h2>
                        <p className="text-[18px] lg:text-[20px] font-normal text-[#151515]">
                            {country?.language}
                        </p>
                    </div>
                    <div className="w-full rounded-[30px] bg-white border border-[#D9D9D9] shadow-md py-4">
                        <img src={timezone} alt={country?.name} width={67} height={67} className="block mx-auto" />
                        <h2 className="text-[30px] lg:text-[30px] font-semibold text-[#151515]">
                            Время
                        </h2>
                        <p className="text-[18px] lg:text-[20px] font-normal text-[#151515]">
                            {country?.timeZone}
                        </p>
                    </div>
                    <div className="w-full rounded-[30px] bg-white border border-[#D9D9D9] shadow-md py-4">
                        <img src={capital} alt={country?.name} width={67} height={67} className="block mx-auto" />
                        <h2 className="text-[30px] lg:text-[30px] font-semibold text-[#151515]">
                            Столица
                        </h2>
                        <p className="text-[18px] lg:text-[20px] font-normal text-[#151515]">
                            {country?.name}
                        </p>
                    </div>
                </div>                   
            </div>
        </div>
    )
}
