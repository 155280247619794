import Header from '../../../widgets/countries/header'
import Enterance from '../../../widgets/countries/enterance'
import Info from '../../../widgets/countries/info'
import Attractions from '../../../widgets/countries/attractions'

export default function Country() {
    return (
        <div>
            <Header />
            <Enterance />
            <Attractions />
            <Info />
        </div>
    )
}
