import { motion } from 'framer-motion';
import { useEffect, useRef } from 'react';

export default function Modal({ isOpen, onClose, children }: { isOpen: boolean, onClose: () => void, children: React.ReactNode }) {
    const modalRef = useRef(null);
    
    useEffect(() => {
      const handleOutsideClick = (event: any) => {
        // @ts-ignore
        if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
          onClose();
        }
      };
      
      if (isOpen) {
        document.addEventListener('mousedown', handleOutsideClick);
      }
      
      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    }, [isOpen, onClose]);
    
    if (!isOpen) return null;
    
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 backdrop-blur-md">
        <motion.div 
          ref={modalRef}
          className="transform transition-all duration-300 animate-in fade-in zoom-in"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
          transition={{ duration: 0.3 }}
        >
          {children}
        </motion.div>
      </div>
    );
  };