import { useState, useContext, useRef } from 'react';
import Slide from '../../../../entity/slide'
import { Button } from '../../../../entity/button-native'
import { AnimatePresence, motion } from 'framer-motion'
import { CountriesContext } from '../../../../App';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import ButtonSlider from '../../../../entity/button-slider';
import Slider from 'react-slick';

function getTranslateXPercent(activeSlide: number, isDesktop: boolean) {
    const big = isDesktop ? 506 : 353
    const small = isDesktop ? 156 : 109
    const gap = isDesktop ? (1200 - (big + small * 4)) / 4 : (768 - (big + small * 3)) / 3

    let offset = 0;

    for (let i = 0; i < activeSlide; i++) {
        offset += small + gap;
    }

    if (isDesktop) {
        offset -= (small + gap) * 2;
    }

    return -offset;
}

export default function DirectionsSlider() {
    const sliderRef = useRef<Slider>(null);
    const directionList = useContext(CountriesContext);
    const [activeSlide, setActiveSlide] = useState(0);
    const navigate = useNavigate();
    const isDesktop = useMediaQuery({ minWidth: 1200 });
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const big = isDesktop ? 506 : 353
    const small = isDesktop ? 156 : isMobile ? 353 : 109
    const gap = isDesktop ? (1200 - (big + small * 4)) / 4 : isMobile ? 768 - big : (768 - (big + small * 3)) / 3

    const handleSlidetoRight = () => {
        if (activeSlide < directionList.length - 1)
            setActiveSlide(activeSlide + 1)
    }

    const handleSlidetoLeft = () => {
        if (activeSlide > 0)
            setActiveSlide(activeSlide - 1)
    }

    if (isMobile) {
        return (<div className='container text-center'>
            <AnimatePresence mode='popLayout' >
                <h2 className="text-[#111111] text-[28px] md:text-[32px] lg:text-[48px] mt-[14px] md:mt-0 leading-[100%] font-semibold">
                    Лучшие направления
                </h2>
                <motion.h3
                    key={activeSlide + 1}
                    initial={{ opacity: 0, x: -10 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.6, ease: "easeInOut" }}
                    className="text-[#111111] text-[20px] md:text-[26px] lg:text-[30px] leading-[100%] font-medium mt-[21px] md:mt-6 lg:mt-[65px]"
                >
                    {directionList[activeSlide].name}
                </motion.h3>
                <motion.p
                    key={activeSlide + directionList.length}
                    initial={{ opacity: 0, x: -10 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.6, ease: "easeInOut" }}
                    className="text-[#111111] text-[14px] md:text-[16px] lg:text-[18px] leading-[120%] font-light mt-[21px] md:mt-[27px] lg:mt-3"
                >
                    {directionList[activeSlide].description}
                </motion.p>
                <motion.div
                    key={activeSlide + directionList.length * 2}
                    initial={{ opacity: 0, x: -10 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.6, ease: "easeInOut" }}
                    className="md:mt-[27px] mt-4 flex lg:block justify-center items-start gap-6"
                >
                    <Button onClick={() => navigate(`/countries/${activeSlide}`)}>
                        Подробнее
                    </Button>
                    <div className='inline-block lg:hidden'>
                        <Button onClick={() => navigate(`/countries`)} type='secondary'>
                            <span className='text-[#111111]'>Все направления</span>
                        </Button>
                    </div>
                </motion.div>
            </AnimatePresence>
            <Slider beforeChange={(curr, next) => setActiveSlide(next)} ref={sliderRef}>
                {directionList.map((slide, index) => (
                    <div key={index} className='text-center'>
                        <div className='flex justify-center items-center mt-6'>
                            <div className='w-[341px]'>
                                <Slide {...slide} active={true} />
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
            <div className="flex justify-center items-center py-9 gap-11">
                <ButtonSlider onClick={() => sliderRef.current?.slickPrev()} direction="left" />
                <ButtonSlider onClick={() => sliderRef.current?.slickNext()} direction="right" />
            </div>
        </div>)
    }

    return <div className="container relative h-full md:pt-[61px] md:pb-[17px] lg:pb-[61px]">

        <div className="static md:absolute md:right-0 lg:right-auto md:top-[61px] lg:left-0 lg:bottom-[389px] lg:text-left text-center" style={{
            width: !isMobile ? `${isDesktop ? (small * 2 + gap * 2) : (small * 3 + gap * 3)}px` : `100%`,
        }}>
            <AnimatePresence mode='popLayout' >
                <h2 className="text-[#111111] text-[28px] md:text-[32px] lg:text-[48px] mt-[14px] md:mt-0 leading-[100%] font-semibold">
                    Лучшие направления
                </h2>
                <motion.h3
                    key={activeSlide + 1}
                    initial={{ opacity: 0, x: -10 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.6, ease: "easeInOut" }}
                    className="text-[#111111] text-[20px] md:text-[26px] lg:text-[30px] leading-[100%] font-medium mt-[21px] md:mt-6 lg:mt-[65px]"
                >
                    {directionList[activeSlide].name}
                </motion.h3>
                <motion.p
                    key={activeSlide + directionList.length}
                    initial={{ opacity: 0, x: -10 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.6, ease: "easeInOut" }}
                    className="text-[#111111] text-[14px] md:text-[16px] lg:text-[18px] leading-[120%] font-light mt-[21px] md:mt-[27px] lg:mt-3"
                >
                    {directionList[activeSlide].description}
                </motion.p>
                <motion.div
                    key={activeSlide + directionList.length * 2}
                    initial={{ opacity: 0, x: -10 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.6, ease: "easeInOut" }}
                    className="md:mt-[27px] lg:mt-4 hidden md:flex lg:block justify-center items-start gap-6"
                >
                    <Button onClick={() => navigate(`/countries/${activeSlide}`)}>
                        Подробнее
                    </Button>
                    <div className='inline-block lg:hidden'>
                        <Button onClick={() => navigate(`/countries`)} type='secondary'>
                            <span className='text-[#111111]'>Все направления</span>
                        </Button>
                    </div>
                </motion.div>
            </AnimatePresence>
        </div>

        <div className='overflow-y-hidden overflow-x-hidden w-full md:h-[470px] lg:h-full relative'>
            <div
                className="flex justify-around md:justify-between items-end transition-all duration-300 static md:absolute md:bottom-0 lg:bottom-4 w-full lg:h-[calc(100%-16px)]"
                style={{
                    width: `${!isMobile ? ((directionList.length - 1) * (small + gap) + big) : directionList.length * 100}${!isMobile ? 'px' : '%'}`,
                    transform: `translateX(${!isMobile ? getTranslateXPercent(activeSlide, isDesktop) : -(100 / directionList.length * activeSlide)}${!isMobile ? 'px' : '%'}`
                }}
            >
                {
                    directionList.map((slide, index) => (
                        <motion.div
                            key={index}
                            animate={{
                                width: index === activeSlide ? `${big}px` : `${small}px`
                            }}
                            transition={{
                                duration: 0.5,
                                ease: "easeInOut"
                            }}
                        >
                            <Slide {...slide} active={index === activeSlide} />
                        </motion.div>
                    ))
                }
            </div>
        </div>

        <div className="flex justify-center items-center py-[37px] md:py-0 gap-11 md:mt-10 lg:mt-0 m md:gap-2 right-0 md:bottom-[17px] lg:bottom-[389px] static lg:absolute" style={{
            width: isDesktop ? `${(small * 2 + gap)}px` : `100%`,
        }}>
            <ButtonSlider onClick={handleSlidetoLeft} direction="left" />
            <ButtonSlider onClick={handleSlidetoRight} direction="right" />
        </div>

        <div className="absolute mx-auto bottom-[61px] rounded-sm md:p-5 lg:p-0 xl:block hidden" style={{
            width: `${big}px`,
            height: `4px`,
            backgroundColor: "#d9d9d9",
            bottom: `61px`,
            left: `calc(50% - ${big / 2}px)`
        }}>
            <motion.div className="absolute h-full bg-[#676767]"
                style={{
                    width: `${100 / directionList.length}%`,
                }}
                animate={{
                    left: `${(100 / directionList.length) * activeSlide}%`
                }}
            />
        </div>
    </div>
}

