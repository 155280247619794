import { useEffect, useRef } from "react"
import parthole from '../../assets/home/directions/porthole.png'
import { motion } from "framer-motion"
import { useMediaQuery } from "react-responsive"
interface SlideProps {
    active?: boolean
    image: string
    tags: string[]
    name: string
    shadow: string,
    layer: string
}

export default function Slide ({image, tags, name, shadow, layer, active}: SlideProps) {
    const ref = useRef<HTMLDivElement>(null)
    const isDesktop = useMediaQuery({minWidth: 1200})


    useEffect(() => {
        if (ref.current) {
        }
    }, [active])

    return (<div className="w-full relative">
        <div className="w-full relative z-0 overflow-hidden rounded-[27.45px] transition-all aspect-[0.75889689039] duration-300" ref={ref}>
            <div className="absolute inset-0 z-40 w-full h-full ">
                <motion.div
                    animate={{
                        opacity: active ? 1 : 0,
                        translateY: active ? 0 : "100%"
                    }}
                    transition={{
                        duration: 0,
                        ease: "easeInOut",
                    }}
                    className="flex justify-center items-center flex-wrap w-full h-[13.92361475%]"
                >
                    <div className="w-full text-center gap-[2px]">
                        {tags.map((tag) => <Tag tag={tag} key={tag}/>)}
                    </div>
                </motion.div>
                <motion.div 
                    animate={{
                        padding: active ? "0 10.267143049%" : "0",
                        height: active ? "75.643191365%" : "100%",
                        position: active ? "relative" : "absolute"
                    }}
                    transition={{
                        duration: 0.5,
                        ease: "easeInOut"
                    }}
                    className="w-full relative inset-0"
                >
                    <div style={{backgroundImage: `url(${process.env.PUBLIC_URL + image})`}} className="bg-cover bg-center mx-[20.694825307%] my-auto h-[79.644268774%] absolute inset-0 bg-no-repeat z-0 rounded-[157.38px]" />
                    <div style={{backgroundImage: `url(${parthole})`}} className="w-full h-full bg-contain bg-center bg-no-repeat inset-0 z-10 flex justify-center items-center absolute" />
                </motion.div>
                <motion.div
                    animate={{
                        opacity: active ? 1 : 0,
                        // translateY: active ? 0 : "100%"
                    }}
                    transition={{
                        duration: 0,
                        ease: "easeInOut"
                    }}
                    className="w-full text-center"
                >
                    <span className="text-white text-[21px] lg:text-[32px] leading-[120%]">
                        {name}
                    </span>
                </motion.div>
            </div>
            <div style={{backgroundImage: `url(${process.env.PUBLIC_URL + image})`}} className="w-full h-full bg-cover bg-center blur-[36.14px] z-0 absolute" />
            <div className="absolute inset-0 z-20 w-full h-full asd" style={{background: shadow}}/>
            <div className="absolute inset-0 z-10 w-full h-full" style={{background: layer}}/>
        </div>
           <motion.div
                animate={{
                    opacity: active ? 0 : 1,
                    translateY: active ? "100%" : "0px",
                    height: active ? "0px" : isDesktop ? "69px" : "auto"
                }}
                transition={{
                    duration: 0.3,
                    ease: "easeInOut"
                }}
                className="w-full text-center mt-1"
            >
                <span className="text-[#111111] text-[15px] leading-[120%]">
                    {name.split(' ')[0]}
                </span>
            </motion.div>
    </div>)
}

function Tag ({tag}: {tag: string}) {
    return (<span className="text-white mx-[4px] my-[4px] text-[14px] lg:text-[15px] font-light border-[0.5px] border-white rounded-[12px] px-[6px] lg:px-2 py-[1.4px] lg:py-[2px] inline-block leading-[120%] ">
        {tag}
    </span>)
}
